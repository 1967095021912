<template>
  <div>
    <div :class="classes.wrapper">
      <div :class="classes.content">
        <div :class="classes.title">
          Лови подписку <br>
          на топовые модели!
        </div>
        <BaseButton
          type="nuxt-link"
          intent="base-primary"
          size="middle"
          :class="['w-full xl:max-w-[226px]', stretchedLinkStyles]"
          :url="'/phone-i?' + queryString"
        >
          Выбрать смартфон
        </BaseButton>
      </div>
      <ol class="text-sm font-bold hidden xl:block">
        <li
          v-for="(step, index) in steps"
          :key="'sale-step-' + index"
          class="mb-1.5 flex items-center"
        >
          <span :class="classes.itemNumber">
            {{ index + 1 }}
          </span>
          {{ step }}
        </li>
      </ol>
    </div>
  </div>
</template>

<script setup lang="ts">
import { stringifyQuery } from 'vue-router'
import { cx } from 'cva'
import { stretchedLinkStyles } from '@/utils'

const classes = {
  wrapper: cx([
    'flex',
    'justify-between',
    'flex-col',
    'xl:flex-row',
    'aspect-square',
    'xs:aspect-[5/4]',
    'xl:aspect-auto',
    'items-center',
    'p-8',
    'xl:ps-14',
    'xl:pe-16',
    'xl:py-12',
    'text-primary-on-color',
    'rounded-xl',
    'relative',
    'bg-cover',
    'xl:bg-contain',
    'bg-no-repeat',
    'bg-sale-pattern-mobile',
    'xl:bg-center-12',
    'xl:bg-sale-pattern',
  ]),
  content: cx([
    'flex',
    'flex-col',
    'justify-between',
    'h-full',
    'w-full',
  ]),
  title: cx([
    'text-[21px]',
    'leading-tight',
    'xl:text-4xl',
    'font-bold',
    'text-center',
    'xl:text-left',
    'mb-4',
  ]),
  itemNumber: cx([
    'mr-2',
    'rounded-full',
    'w-5',
    'h-5',
    'flex',
    'items-center',
    'justify-center',
    'bg-snow-white-500',
  ]),
}

const steps = [
  'Выберите срок',
  'Пользуйтесь без ограничений',
  'Обменяйте на новый или оставьте',
]

const modelSlugs = [
  '8a99a892-1c4f-11ec-8184-005056011ef2',
  '44526e13-2f21-11eb-816b-005056011ef2',
]

const memorySlugs = ['64gb', '128gb']

const queryString = stringifyQuery({
  c_models_slug: modelSlugs.join(','),
  c_memory_slugs: memorySlugs.join(','),
})
</script>